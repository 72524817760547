import "./index.scss";

// const apiBaseUrl = process.env.REACT_APP_API_URL;
const apiBaseUrl = "https://puz.supply/api";
// console.log(apiBaseUrl );
console.log(process);

$(".tab_header_btn").on("click", function () {
  $(".tab-angle").removeClass("tab-angle-active");
  const tabHeaderContent = $(this).parents(".tab_header__item");
  const tabHeaderContentWeight = tabHeaderContent.width();
  const id_content = $(tabHeaderContent).data("id_content");
  const content_height = heightContent(id_content);

  if (
    document
      .getElementById(id_content)
      .classList.contains("tab_main_item-active")
  ) {
    $(".tab_wrap").removeClass("tab-active");
    $(".tab-angle").removeClass("tab-angle-active");
    clearTab(".tab_main_item", "tab_main_item-active");

    $(`#${id_content}`).parents(".tab_main").css("min-height", 0);
  } else {
    const tabOfset = $(tabHeaderContent).offset();
    $("body,html").animate({ scrollTop: tabOfset.top - 150 }, 1000);

    clearTab(".tab_main_item", "tab_main_item-active");
    activeContent(id_content, content_height);

    $(".tab-angle").css(
      "left",
      tabHeaderContentWeight / 2 +
        $(tabHeaderContent).offset().left -
        $(".tab_header").offset().left
    );

    $(".tab_wrap").addClass("tab-active");
    $(".tab-angle").addClass("tab-angle-active");
  }

  // activeHeaderTab()
});

function clearTab(tabClass, tavClassActive) {
  $(tabClass).removeClass(tavClassActive);
}
function heightContent(element) {
  return document.getElementById(element).offsetHeight;
}
function activeContent(idContent, contentHeight) {
  $(`#${idContent}`).parents(".tab_main").css("min-height", contentHeight);
  $(`#${idContent}`).addClass("tab_main_item-active");
}

$(".btn-tender").on("click", function () {
  event.preventDefault();
  if (!$(this).hasClass("btn-standart-disable")) {
    const nomenclature = $("input[name='select_nomenclature']").val();
    let query = `${apiBaseUrl}/rozrah/`;

    query += `?nomen_id=${nomenclature}`;

    fetch(query)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var tpl = document.querySelector(".tender_price__wrap");
        tpl.innerHTML = "";

        for (var key in response) {
          tpl.innerHTML += `<div class=" tender_price">
                    <div class="tender_price-date">${response[key].datetime}</div>
                    <div class="tender_price-value">${response[key].price}</div>
                </div>`;
        }

        $(".tender_price_box").addClass("tender_price_box-active");
        $(".tender_button").addClass("tender_button-active");
      });
  }
});

$(".btn_link_anchor").on("click", function () {
  event.preventDefault();
  const id_content = $(this).attr("href");
  scroll_tar(id_content);
});

if (localStorage.getItem("idAnchor")) {
  const id_content = localStorage.getItem("idAnchor");
  delete localStorage.idAnchor;
  scroll_tar(id_content);
}
function scroll_tar(id_content) {
  $(".tab-angle").removeClass("tab-angle-active");
  const tabHeaderContent = $(`[data-id_content='${id_content}']`);
  const tabHeaderContentWeight = tabHeaderContent.width();
  const content_height = heightContent(id_content);

  const tabOfset = $(tabHeaderContent).offset();
  $("body,html").animate({ scrollTop: tabOfset.top - 150 }, 1000);
  clearTab(".tab_main_item", "tab_main_item-active");
  activeContent(id_content, content_height);

  $(".tab-angle").css(
    "left",
    tabHeaderContentWeight / 2 +
      $(tabHeaderContent).offset().left -
      $(".tab_header").offset().left
  );

  $(".tab_wrap").addClass("tab-active");
  $(".tab-angle").addClass("tab-angle-active");
}

// SELECT NOMENCLATURES

let directionsValueId;

let select_direction = $(".select_direction").select2({
  dropdownAutoWidth: true,

  width: "resolve",

  placeholder: gettext("Напрям"),
  maximumSelectionLength: 3,
  language: {
    noResults: function () {
      return gettext("Напрямку не знайдено");
    },
  },
});

fetch(`${apiBaseUrl}/directions/`)
  .then(function (response) {
    return response.json();
  })
  .then(function (response) {
    var option = new Option("Усі напрямки", "all", false, true);
    select_direction.append(option).trigger("change");
    for (var key in response) {
      var option = new Option(
        response[key].name,
        response[key].id,
        false,
        false
      );
      select_direction.append(option).trigger("change");
    }
  });

let select_nomenclature = $(".select_nomenclature").select2({
  dropdownAutoWidth: true,
  width: "resolve",
  placeholder: gettext("Номенклатура"),
  language: {
    noResults: function () {
      return gettext("Номенклатури не знайдено");
    },
  },
  ajax: {
    url: `${apiBaseUrl}/paginated_nomenclatures/`,
    data: function (params) {
      var query = {
        page_size: 30,
        search_query: params.term,
        page_number: params.page || 1,

        direction_id: directionsValueId === "all" ? "" : directionsValueId,
      };

      return query;
    },
    processResults: function (data, params, ...props) {
      params.page = params.page || 1;

      let options = data.results.map((item) => {
        return {
          id: item.id,
          text: item.name,
          item,
          direction: item.direction,
        };
      });

      return {
        results: options,
        pagination: {
          more: params.page * 30 < data.count,
        },
      };
    },
  },
});

// select chenge

$(".select_direction").on("select2:select", function (e) {
  $(select_nomenclature).select2("enable");
  $(".select_direction").parent().find(".filter_input").val(e.params.data.id);
  $(select_nomenclature).val([]).trigger("change");
  $(select_nomenclature).empty();

  let select_value = e.params.data.id;

  directionsValueId = select_value;
});

$(".select_nomenclature").on("select2:select", function (e) {
  $(".tender_button").find(".btn").removeClass("btn-standart-disable");
  $(".select_nomenclature")
    .parent()
    .find(".filter_input")
    .val(e.params.data.id);

  $(select_direction)
    .select2()
    .val(!!e.params.data.direction ? e.params.data.direction.id : "all")
    .trigger("change");

  if ($(".tender_price_box").hasClass("tender_price_box-active")) {
    const nomenclature = $("input[name='select_nomenclature']").val();
    let query = `${apiBaseUrl}/rozrah/`;

    query += `?nomen_id=${e.params.data.id}`;

    fetch(query)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        var tpl = document.querySelector(".tender_price__wrap");
        tpl.innerHTML = "";

        for (var key in response) {
          tpl.innerHTML += `<div class=" tender_price">
                        <div class="tender_price-date">${response[key].datetime}</div>
                        <div class="tender_price-value">${response[key].price}</div>
                    </div>`;
        }

        $(".tender_price_box").addClass("tender_price_box-active");
        $(".tender_button").addClass("tender_button-active");
      });
  }
});
